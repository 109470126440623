<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('todos-sub-action-modal', {id: 0}, afterSave, {width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.todos-action.label.name') }}</b-th>
                            <b-th>{{ $t('settings.todos-action.label.action') }}</b-th>
                            <b-th>{{ $t('settings.todos-action.label.actions') }}</b-th>
                        </b-thead>
                        <draggable :class="{[`cursor-grabbing`]: dragging === true}" v-model="actions"
                                   group="columns" @start="dragging = true" @end="dragging = false" tag="tbody"
                                   handle=".handle">
                            <b-tr v-for="action in actions" :key="action.id">
                                <b-td>{{ action.name }}</b-td>
                                <b-td>{{ action.action.name }}</b-td>
                                <b-td>
                                    <b-button
                                        @click="$root.$children[0].openModal('todos-sub-action-modal', {id: action.id},afterSave,{width: '800px'})"
                                        size="sm"
                                        variant="primary"
                                        class="mr-2"
                                        :title="$t('common.title.edit')"
                                    >
                                        <font-awesome-icon icon="edit"/>
                                    </b-button>

                                    <b-button @click="deleteAction(action.id)"
                                              size="sm"
                                              variant="danger"
                                              :title="$t('common.title.delete')"
                                    >
                                        <font-awesome-icon icon="trash"/>
                                    </b-button>
                                </b-td>
                            </b-tr>
                        </draggable>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable"

export default {
    name: 'Settings-todos-sub-action',
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            actions: [],
            dragging: false,
        }
    },
    methods: {
        ...mapGetters('TodosAction', ['getSubActions']),
        shown() {
            const actionsFetch = this.$store.dispatch('TodosAction/fetchSubActions')

            Promise.all([actionsFetch])
                .then(() => {
                    this.actions = this.getSubActions()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        afterSave() {
            this.$store.dispatch('CommonData/fetchCommonData')
            this.shown()
            this.$root.afterSettingsUpdate()
        },
        deleteAction(id) {
            this.$removeConfirm('TodosAction/deleteSubAction', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>